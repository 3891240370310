import { FC } from 'react';

import * as Styles from './Loader.styles';

type LoaderType = {
  isLoading: boolean;
};

const Loader: FC<LoaderType> = props => {
  const { isLoading } = props;

  return (
    <Styles.LoaderContainer>
      { isLoading && <Styles.Loader></Styles.Loader> }
    </Styles.LoaderContainer>
  );
}

export default Loader;
