import { FC } from 'react';

import Button from '../Button/Button';
import HeaderLogo from '../../assets/images/logo.svg';
import { removeStorageToken } from '../../helpers/storage/token';

import * as Styles from './Header.styles'

type HeaderType = {
  isButtonVisible: boolean;
  setToken: (token: string) => void;
};

const Header: FC<HeaderType> = props => {
  const { isButtonVisible, setToken } = props;

  const handleLogout = () => {
    removeStorageToken();
    setToken('');
  };

  return (
    <Styles.HeaderContainer>
      <Styles.Logo src={HeaderLogo} alt="IoT Oak Logo" />
      {isButtonVisible && (
        <Styles.ButtonContainer>
          <Button 
            color="green"
            name="Log out"
            size="small"
            type="button"
            clickHandler={handleLogout}
          />
        </Styles.ButtonContainer>
      )}
    </Styles.HeaderContainer>
  );
};

export default Header;
