import styled from 'styled-components';

import { colors } from '../../theme';

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 48px;
  background-color: ${colors.lightBlack};
`;

export const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  height: 100%;
`;

export const Logo = styled.img`
  margin: 5px 0;
  height: 38px;
`;
