import { FC, useState, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Header from './components/Header/Header';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import { DASHBOARD_URL } from './helpers/constants/navigation';
import { getStorageToken } from './helpers/storage/token';

const App: FC<{}> = () => {
  const [token, setToken] = useState<string>('');
  const history = useHistory();
  const isLogoutButtonVisible = token !== '';

  useEffect(() => {
    const storageToken = getStorageToken();

    if (storageToken) {
      setToken(storageToken);
      history.push(DASHBOARD_URL);
    }
  }, []);

  return (
    <>
      <Header isButtonVisible={isLogoutButtonVisible} setToken={setToken} />
      <Switch>
        {token && (
          <Route path="/dashboard" exact>
            <Dashboard token={token} setToken={setToken} />
          </Route>
        )}
        <Route path="/login" exact>
          <Login setToken={setToken} />
        </Route>
        <Redirect to="/login" />
      </Switch>
    </>
  );
}

export default App;
