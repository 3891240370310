import { FC } from 'react';

import * as Styles from './Input.styles';

export type LoginPlacementType = 'left' | 'center';

type LabelType = {
  value: string;
  placement: LoginPlacementType;
};

type InputType = {
  value: string;
  name: string;
  type: 'text' | 'password';
  changeHandler: (value: string) => void;
  label?: LabelType;
  disabled?: boolean;
  placeholder?: string;
  width?:string
};

const Input: FC<InputType> = props => {
  const { value, name, type, changeHandler, label, disabled, placeholder = '', width="362px" } = props;

  return (
    <Styles.InputContainer>
      {label && (
        <Styles.InputLabel 
          htmlFor={`${name}-input`}
          placement={label.placement}
        >
          {label.value}
        </Styles.InputLabel>
      )}
      <Styles.Input
        id={`${name}-input`}
        placeholder={placeholder} 
        value={value}
        type={type}
        name={name}
        onChange={(event) => changeHandler(event.target.value)}
        disabled={disabled}
        width={width}
      />
    </Styles.InputContainer>
  );
};

export default Input;
