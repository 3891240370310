import { FC } from 'react';
import Select, { OptionTypeBase } from 'react-select';

import ArrowUpIcon from '../../assets/icons/arrow-up.svg';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg';

import * as Styles from './Select.styles';
import { selectStyles } from './Select.config';

type OptionType = {
  id: string;
  value: string;
  label: string;
};

type SelectType = {
  options: Array<OptionType>;
  selectedValue: OptionTypeBase | null;
  selectHandler: (value: OptionTypeBase) => void;
  name: string;
  isLoading: boolean;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
};

const SelectComponent: FC<SelectType> = props => {
  const { options, selectedValue, selectHandler, name, isLoading, label, disabled, placeholder = ''} = props;

  return (
    <Styles.SelectContainer>
      {label && (
        <Styles.SelectLabel htmlFor={`${name}-select`}>{label}</Styles.SelectLabel>
      )}
      <Select
        id={`${name}-select`}
        components={{ 
          DropdownIndicator: ({ isFocused }) => <img src={isFocused ? ArrowUpIcon : ArrowDownIcon} alt="arrow-icon" />, 
          IndicatorSeparator: () => null 
        }}
        onChange={value => selectHandler(value)}
        value={selectedValue}
        styles={selectStyles}
        options={options}
        placeholder={placeholder}
        isSearchable={false}
        name={name}
        blurInputOnSelect
        isLoading={isLoading}
        disabled={disabled}
      />
    </Styles.SelectContainer>
  );
};

export default SelectComponent;
