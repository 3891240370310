import { ServerPartnersType, PartnersType } from './Dashboard';

export const generatePartnersList = (partners: Array<ServerPartnersType>): Array<PartnersType> => (
  partners.map(partner => {
    return {
      id: partner.id,
      value: partner.name,
      label: partner.name
    }
  })
);
