import { FC } from 'react';

import { colors } from '../../theme';

import * as Styles from './Button.styles';

export type ButtoColorType = 'black' | 'green';
export type ButtonSizeType = 'small' | 'big';
type ButtonType = {
  name: string;
  color: ButtoColorType,
  clickHandler?: () => void;
  disabled?: boolean;
  size?: ButtonSizeType;
  type?: 'submit' | 'button';
};

const Button: FC<ButtonType> = props => {
  const { name, color, clickHandler, disabled, size = 'big', type = 'submit' } = props;

  const isBigButton = size === 'big';
  const isGreenButton = color === 'green';

  return (
    <Styles.Button 
      disabled={disabled}
      type={type}
      color={isGreenButton ? colors.green : colors.lightBlack} 
      width={isBigButton ? '362px' : '175px'}
      padding={isBigButton ? '16px 0' : '10px 0'}
      onClick={clickHandler}
    >
      {name}
    </Styles.Button>
  );
};

export default Button;
