import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Response from '../../components/Response/Response';
import Loader from '../../components/Loader/Loader';
import { LOGIN_URL } from '../../helpers/constants/api';
import { DASHBOARD_URL } from '../../helpers/constants/navigation';
import { isFormFilled } from '../../helpers/checkers/form';
import { saveStorageToken } from '../../helpers/storage/token';
import LogoImage from './../../assets/images/logo.svg';

import * as Styles from './Login.styles';

type LoginType = {
  setToken: (token: string) => void;
};

const Login: FC<LoginType> = props => {
  const { setToken } = props;
  const history = useHistory();

  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (isFormFilled([login, password])) {
      setIsLoading(true);

      const formData = new FormData(event.currentTarget);

      axios.post(LOGIN_URL, formData)
        .then(response => {
          const token = response.data.token;
          
          setToken(token);
          saveStorageToken(token);
          history.push(DASHBOARD_URL);
        })
        .catch(error => {
          const status = error.response.status;
          const message = status === 403 ? 'Wrong login credentials' : `Could not log in\n${error.message}`;

          setError(message);
          setIsLoading(false);
        });
    } else {
      setError('Login credentials should not be empty');
    }
  };

  return (
    <Styles.LoginContainer>
      <Styles.Logo src={LogoImage} alt="IoT Oak Logo" />
      <Styles.FormContainer onSubmit={handleFormSubmit}>
        <Input 
          type="text"
          name="username"
          value={login}
          changeHandler={setLogin}
          label={{ value: "Name/Email address", placement: "left" }}
          disabled={isLoading}
        />
        <Input 
          type="password"
          name="password"
          value={password}
          changeHandler={setPassword}
          label={{ value: "Password", placement: "left" }}
          disabled={isLoading}
        />
        <Styles.ButtonContainer>
          <Button 
            color="black"
            name="Log in"
            disabled={isLoading}
          />
        </Styles.ButtonContainer>
      </Styles.FormContainer>
      <Loader isLoading={isLoading} />
      {error && (
        <Response 
          message={error}
          type="error"
        />
      )}
    </Styles.LoginContainer>
  );
};

export default Login;
