import { FC, useState, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';
import axios from 'axios';

import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import Response, { ResponseType, ResponsePresentationType } from '../../components/Response/Response';
import { CLOSESSH_URL, INVENTORIES_URL, OPENSSH_URL, PARTNERS_URL, SECURE_DEVICE_URL } from '../../helpers/constants/api';
import { isFormFilled } from '../../helpers/checkers/form';
import { removeStorageToken } from '../../helpers/storage/token';
import * as server from '../../helpers/constants/server';

import { generatePartnersList } from './Dashboard.helpers';
import * as Styles from './Dashboard.styles';

type DashboardType = {
  token: string;
  setToken: (token: string) => void;
};

export type ServerPartnersType = {
  id: string;
  name: string;
};

export type PartnersType = {
  id: string;
  value: string;
  label: string;
};

const CALLS_LIMIT = 300;
const CALL_TIMEOUT = 1000;
const initialSelectedPartner = {
  id: '',
  value: '',
  label: ''
};

const Dashboard: FC<DashboardType> = props => {
  const { token, setToken } = props;
  const requestHeaders = { headers: { 'Authorization': `Bearer ${token}` } };

  const [deviceId, setDeviceId] = useState<string>('');
  const [port, setPort] = useState('10000');
  const [selectedPartner, setSelectedPartner] = useState<OptionTypeBase>(initialSelectedPartner);
  const [selectedInventory, setSelectedInventory] = useState<OptionTypeBase>(initialSelectedPartner);
  const [partnersList, setPartnersList] = useState<Array<PartnersType>>([]);
  const [inventoriesList, setInventoriesList] = useState<Array<PartnersType>>([]);
  const [responseMessage, setResponseMessage] = useState<ResponseType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => getPartnersList(), []);
  useEffect(() => getInventoriesList(), []);

  const clearInputs = (): void => {
    setDeviceId('');
    setSelectedPartner(initialSelectedPartner);
    setSelectedInventory(initialSelectedPartner);
  };

  const logoutUnauthorizedUser = (): void => {
    removeStorageToken();
    setToken('');
  };

  const presentIncomingData = (type: ResponsePresentationType, message: string): void => {
    setResponseMessage({
      type: type,
      message: message
    });

    setIsLoading(false);
  };

  const handleIncomingError = (status: number, message: string): void => {
    if (status === 401) {
      logoutUnauthorizedUser();
    } else {
      presentIncomingData('error', message);
    }
  };

  const getPartnersList = (): void => {
    setIsLoading(true);
    let partnersList = Array<PartnersType>()
    axios.get(PARTNERS_URL, requestHeaders)
      .then(response => {
        partnersList = generatePartnersList(response.data.partners);
      })
      .catch((error) => {
        const status = error.response.status;
        const message = `Could not load Partner list: ${error.message}`;

        handleIncomingError(status, message);
      });

      axios.get(INVENTORIES_URL, requestHeaders)
        .then(response => {
          partnersList.push(...generatePartnersList(response.data.partners));
          setPartnersList(partnersList);
          setIsLoading(false);
        })
        .catch((error) => {
          const status = error.response.status;
          const message = `Could not load Partner list: ${error.message}`;

          handleIncomingError(status, message);
        });
      
      
  };

  const getInventoriesList = (): void => {
    setIsLoading(true);

    axios.get(INVENTORIES_URL, requestHeaders)
      .then(response => {
        setInventoriesList(generatePartnersList(response.data.partners));
        setIsLoading(false);
      })
      .catch((error) => {
        const status = error.response.status;
        const message = `Could not load Inventories list: ${error.message}`;

        handleIncomingError(status, message);
      });
  };

  const checkRequestStatus = (jobId: string): void => {
    let calls = 0;
    setIsLoading(true);
    callRequestStatus(jobId, calls);
  };

  const callRequestStatus = (jobId: string, calls: number): void => {
    axios.get(`${SECURE_DEVICE_URL}/${jobId}`, requestHeaders)
      .then(response => {
        const { status, result, message } = response.data;
        const isSuccess = response.status === 200;
        const resultType = result === server.RESULT_SUCCESS ? 'success' : 'error';

        if (isSuccess && status === server.STATUS_DONE) {
          presentIncomingData(resultType, message);
          clearInputs();
        } else if (isSuccess && status === server.STATUS_ONGOING && calls <= CALLS_LIMIT) {
          calls++;
          presentIncomingData(resultType, message);
          setTimeout(() => callRequestStatus(jobId, calls), CALL_TIMEOUT);
        } else if (result === server.RESULT_FAIL) {
          handleIncomingError(response.status, message);
        } else {
          presentIncomingData('error', 'Timeout - could not link and secure device');
        }
      })
      .catch(error => {
        const status = error.response.status;
        const message = `Could not list and secure device: ${error.message}`;

        handleIncomingError(status, message);
      });
  };

  const handleSecureDevice = (): void => {
    if (isFormFilled([deviceId, selectedPartner.value, selectedInventory.value])) {
      const formData = new FormData();
      formData.append('partner', selectedPartner.id);
      formData.append('deviceID', deviceId);
      formData.append('inventory', selectedInventory.id);

      axios.post(SECURE_DEVICE_URL, formData, requestHeaders)
        .then(response => checkRequestStatus(response.data.jobID))
        .catch(error => {
          const status = error.response.status;
          const message = `Could not list and secure device: ${error.message}`;

          handleIncomingError(status, message);
        });
    } else {
      setResponseMessage({
        message: 'ICCID, Partner and Inventory should not be empty',
        type: 'error'
      });
    }
  };

  const handleOpenSSH = (): void => {
    if (isFormFilled([deviceId, selectedInventory.value, port])) {
      const formData = new FormData();
      formData.append('port', port);
      formData.append('deviceID', deviceId);
      formData.append('inventory', selectedInventory.id);

      axios.post(OPENSSH_URL, formData, requestHeaders)
        .catch(error => {
          const status = error.response.status;
          const message = `Could not open ssh connection: ${error.message}`;

          handleIncomingError(status, message);
        });
    } else {
      setResponseMessage({
        message: 'ICCID, Inventory and port should not be empty',
        type: 'error'
      });
    }
  };

  const handleCloseSSH = (): void => {
    if (isFormFilled([deviceId, selectedInventory.value])) {
      const formData = new FormData();
      formData.append('deviceID', deviceId);
      formData.append('inventory', selectedInventory.id);

      axios.post(CLOSESSH_URL, formData, requestHeaders)
        .catch(error => {
          const status = error.response.status;
          const message = `Could not close ssh connection: ${error.message}`;

          handleIncomingError(status, message);
        });
    } else {
      setResponseMessage({
        message: 'ICCID and Inventory should not be empty',
        type: 'error'
      });
    }
  };

  return (
    <Styles.DashboardContainer>
      <Styles.FormContainer>
        <Input 
          type="text"
          name="deviceID"
          label={{ value: "Enter ICCID", placement: "center" }}
          value={deviceId}
          placeholder="Enter the number here"
          changeHandler={setDeviceId}
          disabled={isLoading}
        />
        <Select 
          name="selected-inventory"
          options={inventoriesList}
          selectedValue={selectedInventory.value ? selectedInventory : null}
          placeholder="Select inventory from this droplist"
          selectHandler={setSelectedInventory}
          isLoading={isLoading}
          disabled={isLoading}
        />
         <Input
          type="text"
          name="port"
          label={{ value: "Port", placement: "center"}}
          value={port}
          placeholder="Enter port"
          changeHandler={setPort}
          disabled={isLoading}
          width="100px"
        />
        <Styles.ButtonsContainer>
            <Button 
              size = "small"
              color="green"
              name="Start ssh tunel"
              disabled={isLoading}
              clickHandler={handleOpenSSH}
            />
            <Button 
              size = "small"
              color="green"
              name="Close ssh tunel"
              disabled={isLoading}
              clickHandler={handleCloseSSH}
            />
        </Styles.ButtonsContainer>
        <Select 
          name="selected-partner"
          label="Select Partner"
          options={partnersList}
          selectedValue={selectedPartner.value ? selectedPartner : null}
          placeholder="Select Partner from this droplist"
          selectHandler={setSelectedPartner}
          isLoading={isLoading}
          disabled={isLoading}
        />
          <Button 
            color="green"
            name="Link and secure device"
            disabled={isLoading}
            clickHandler={handleSecureDevice}
          />
      </Styles.FormContainer>
      <Loader isLoading={isLoading} />
      {responseMessage && (
        <Response 
          message={responseMessage.message}
          type={responseMessage.type}
        />
      )}
    </Styles.DashboardContainer>
  );
};

export default Dashboard;
