import styled from 'styled-components';

import { colors } from '../../theme';

type ButtonType = {
  width: string;
  padding: string;
};

export const Button = styled.button<ButtonType>`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  border: none;
  border-radius: 4px;
  color: ${colors.white};
  background-color: ${({ color }) => color};
  font-weight: 500;
  outline: none;
  cursor: pointer;

  :active {
    background-color: ${({ color }) => `${color}E0`};
  }

  :disabled {
    cursor: not-allowed;
  }
`;
