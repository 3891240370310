import { CSSProperties } from 'react';
import { ControlProps } from 'react-select';

import { colors } from '../../theme';

export const selectStyles: object = {
  container: (provided: CSSProperties) => ({
    ...provided,
    minWidth: '474px'
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: colors.darkGrey,
    border: 0,
    boxShadow: 'none',
    padding: '14px 24px'
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: colors.grey,
    boxShadow: 'none',
    padding: 20,
    marginTop: 0
  }),
  option: (provided: CSSProperties, state: ControlProps<{}, false>) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.darkGrey : null,
    color: colors.black,
    borderRadius: 3
  })
};
