import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 98px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  height: 96px;
  margin: 0 auto 32px;
`;

export const ButtonContainer = styled.div`
  margin: 16px 0;
`;
