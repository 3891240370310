import styled from 'styled-components';

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  background-color: ${({ color }) => `${color}1F`};
  width: 604px;
  border-radius: 8px;
  padding: 24px 0;
  margin: 30px auto 0;
`;

export const Icon = styled.img``;

export const MessageContainer = styled.div`
  margin: 16px 0;
`;
