import styled from 'styled-components';

import { colors } from '../../theme';

import { LoginPlacementType } from './Input';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const InputLabel = styled.label<{ placement: LoginPlacementType }>`
  margin-bottom: ${({ placement }) => placement === 'left' ? '8px' : '16px'};
  text-align: ${({ placement }) => placement};
`;

export const Input = styled.input`
  width: ${({ width }) => width};
  padding: 16px;
  border: none;
  border-radius: 4px;
  background-color: ${colors.darkGrey};
  outline: none;
  text-align:center;

  :active,
  :focus {
    background-color: ${colors.grey};
  }
`;
