import styled, { keyframes } from 'styled-components';

import { colors } from '../../theme';

const loaderAnimation = keyframes`
  0% { 
    background-color: ${colors.green} 
  }
  100% {
    background-color: ${colors.grey};
  }
`;

export const LoaderContainer = styled.div`
  margin: 0 auto;
  height: 10px;
`;

export const Loader = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${colors.green};
  color: ${colors.green};
  animation: ${loaderAnimation} 1s infinite linear alternate;
  animation-delay: .5s;

  ::before, 
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  ::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.green};
    color: ${colors.green};
    animation: ${loaderAnimation} 1s infinite alternate;
    animation-delay: 0s;
  }

  ::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.green};
    color: ${colors.green};
    animation: ${loaderAnimation} 1s infinite alternate;
    animation-delay: 1s;
  }
`;
