import { FC } from 'react';

import { colors } from '../../theme';
import SuccessIcon from '../../assets/icons/success.svg';
import FailureIcon from '../../assets/icons/failure.svg';

import * as Styles from './Response.styles';

export type ResponsePresentationType = 'success' | 'error';
export type ResponseType = {
  type: ResponsePresentationType,
  message: string;
};

const Response: FC<ResponseType> = props => {
  const { type, message } = props;

  const isSuccessResponse = type === 'success';
  const containerColor = isSuccessResponse ? colors.green : colors.red;
  const icon = isSuccessResponse ? SuccessIcon : FailureIcon;

  return (
    <Styles.ResponseContainer color={containerColor}>
      <Styles.Icon 
        src={icon} 
        alt={isSuccessResponse ? 'Success Icon' : 'Failure Icon'}
      />
      <Styles.MessageContainer>
        {message}
      </Styles.MessageContainer>
    </Styles.ResponseContainer>
  );
};

export default Response;
