import { TOKEN_NAME } from '../constants/token';

export const saveStorageToken = (token: string): void => {
  localStorage.setItem(TOKEN_NAME, token);
};

export const getStorageToken = (): string | null => {
  return localStorage.getItem(TOKEN_NAME);
};

export const removeStorageToken = (): void => {
  localStorage.removeItem(TOKEN_NAME);
};
