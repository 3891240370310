import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const SelectLabel = styled.label`
  margin-bottom: 16px;
  text-align: center;
`;
